// Header.tsx
import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { logo } from '../assets'

const Header: React.FC = () => {
    return (
        <Navbar expand="lg" style={{ padding: '1rem 1rem 1rem 2rem', backgroundColor: 'var(--faint-purple)', paddingLeft: 'var(--global-margin-left)', paddingRight: 'var(--global-margin-left)' }}>
            {/* <div className='ashera-row' style={{ width: '100 %' }}> */}
            <Navbar.Brand as={Link} to="/">
                <Image
                    src={logo['darker-purple-with-font']}
                    alt="Ashera Logo"
                    style={{ height: '40px', }}
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                {/* Use ms-auto to push the Nav links to the right in Bootstrap 5 */}
                <Nav className="ms-auto">
                    <Nav.Link as={Link} to="/women">Women</Nav.Link>
                    <Nav.Link as={Link} to="/science">Tech and Science</Nav.Link>
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                    <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            {/* </div> */}
        </Navbar>


    );
};

export default Header;
