// src/assets.ts
import { loadAssets } from './utils/loadAssets';

const homeCarouselContext = require.context('./assets/home/carousel', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const homeCarousel = loadAssets(homeCarouselContext);

const logoContext = require.context('./assets/logo', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const logo = loadAssets(logoContext);

const reportContext = require.context('./assets/report', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const report = loadAssets(reportContext);

const areasOfDetectionContext = require.context('./assets/home/areas_of_detection', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const areasOfDetection = loadAssets(areasOfDetectionContext);

const aboutCarouselContext = require.context('./assets/about/carousel', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const aboutCarousel = loadAssets(aboutCarouselContext);

const scienceCarouselContext = require.context('./assets/science/carousel', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const scienceCarousel = loadAssets(scienceCarouselContext);

const scienceOtherContext = require.context('./assets/science/other', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const scienceOther = loadAssets(scienceOtherContext);

const womenCarouselContext = require.context('./assets/women/carousel', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const womenCarousel = loadAssets(womenCarouselContext);

const womenOtherContext = require.context('./assets/women/other', true, /\.(png|webp|svg|jpeg|jpg)$/);
export const womenOther = loadAssets(womenOtherContext);