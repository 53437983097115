import React, { useEffect, useState, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import TypingDots from './TypingDots'; // Assuming TypingDots is in the same directory
import { logo } from '../assets'

interface Message {
    id: number;
    text: string;
    time: string;
    isExpert: boolean;
}

const ChatComponent: React.FC<{ messages: Message[] }> = ({ messages }) => {
    const [displayedMessages, setDisplayedMessages] = useState<Message[]>([]);
    const [isTyping, setIsTyping] = useState(false);
    const [isFormActive, setIsFormActive] = useState(false); // Controls form activity
    const [inputValue, setInputValue] = useState('');
    const [hasStarted, setHasStarted] = useState(false); // Ensure process only starts once
    const containerRef = useRef<HTMLDivElement | null>(null); // Reference for intersection observer
    const messagesContainerRef = useRef<HTMLDivElement | null>(null);

    // Intersection Observer to start message display when parent is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !hasStarted) {
                    setHasStarted(true); // Ensure we only start once
                }
            },
            { threshold: 0.1 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [hasStarted]);

    // Message display function, triggered only once hasStarted is true
    useEffect(() => {
        if (!hasStarted) return;

        const showMessages = async () => {
            for (let i = 0; i < messages.length; i++) {

                if (messages[i].isExpert) {
                    setIsTyping(true);
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }


                const currentTime = new Date().toLocaleTimeString(); // Get the current time

                // Set the message with updated time
                setDisplayedMessages((prev) => [
                    ...prev,
                    { ...messages[i], time: currentTime }
                ]);

                setIsTyping(false);
                await new Promise((resolve) => setTimeout(resolve, 500)); // Message display delay
            }

            // After all messages are displayed, show "try it yourself" and activate form
            setTimeout(() => {
                setDisplayedMessages((prev) => [

                    { id: Date.now(), text: 'Try it yourself and learn if our test of for you!', time: '', isExpert: true }
                ]);
                setIsFormActive(true); // Activate the form after messages are displayed
            }, 5000);
        };

        showMessages();
    }, [hasStarted, messages]);

    useEffect(() => {
        // Cleanup function when component unmounts
        return () => {
            resetConversation();
        };
    }, []);

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [displayedMessages]);

    const resetConversation = async () => {
        try {
            const response = await fetch('/api/reset_conversation', {
                method: 'POST',
                credentials: 'include',
            });
            const data = await response.json();
            console.log(data.message);
        } catch (error) {
            console.error('Error resetting conversation:', error);
        }
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (inputValue.trim() === '') return;

        const newMessage: Message = {
            id: Date.now(),
            text: inputValue,
            time: new Date().toLocaleTimeString(), // Use current time for user message
            isExpert: false
        };

        setDisplayedMessages((prev) => [...prev, newMessage]);
        setInputValue('');

        // Simulate an API call to get the response
        const response = await fetch('/api/ask_question', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: inputValue }),
            credentials: 'include',  // Include cookies in the request
        });
        const data = await response.json();

        setIsTyping(true); // Show typing animation while waiting for response
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsTyping(false);

        setDisplayedMessages((prev) => [
            ...prev,
            { id: Date.now(), text: data.message, time: new Date().toLocaleTimeString(), isExpert: true }
        ]);
    };

    return (
        <div ref={containerRef}>
            <div ref={messagesContainerRef} style={{ height: '80vh', overflowY: 'auto', padding: '1rem', backgroundColor: '#fafafa', borderRadius: '8px', border: '1px solid var(--darker-purple)' }}>
                {displayedMessages.map((message) => (
                    <div
                        key={message.id}
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            marginBottom: '1rem',
                            flexDirection: message.isExpert ? 'row' : 'row-reverse'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: message.isExpert ? 'var(--faint-purple)' : 'var(--darker-purple)',
                                color: message.isExpert ? '#333' : '#fff',
                                padding: '1rem',
                                borderRadius: '15px',
                                maxWidth: '75%',
                                alignSelf: message.isExpert ? 'flex-start' : 'flex-end',
                                position: 'relative',
                            }}
                        >
                            <p style={{ margin: 0 }}>{message.text}</p>
                            <small style={{ color: message.isExpert ? '#666' : '#dcdcdc', marginTop: '0.5rem', display: 'block' }}>
                                {message.time}
                            </small>
                        </div>
                        {message.isExpert && (
                            <img
                                src={logo['darker-purple']}
                                alt="Expert"
                                style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '0.5rem' }}
                            />
                        )}
                    </div>
                ))}

                {isTyping && (
                    <div style={{
                        backgroundColor: 'var(--faint-purple)',
                        color: '#333',
                        padding: '1rem',
                        borderRadius: '15px',
                        maxWidth: '75%',
                        alignSelf: 'flex-start',
                        position: 'relative',
                    }}>
                        <TypingDots />
                    </div>
                )}
            </div>
            <Form style={{ border: '1px solid var(--darker-purple)', borderRadius: '8px', }} className="mt-3" onSubmit={handleFormSubmit}>
                <Form.Control
                    type="text"
                    placeholder="Ask ashera anything..."
                    style={{ padding: '0.75rem 1.5rem', background: isFormActive ? '#fafafa' : '#E8E8E8', border: isFormActive ? '5px solid var(--darker-purple)' : 'none' }}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    disabled={!isFormActive} // Disable the form input initially
                />
            </Form>
        </div>
    );
};

export default ChatComponent;
