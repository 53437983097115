// pages/Privacy.tsx
import React from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';

const Privacy: React.FC = () => {
    return (
        <>
            <Container className='background-01 home-section' fluid >
                <Row className='ashera-row'>
                    {/* Left Column */}
                    <Col md={6} className="d-flex justify-content-center align-items-center text-center">
                        <h1 style={{ color: '#4B0082', fontWeight: 'bold' }}>Privacy</h1>
                    </Col>

                    {/* Right Column: Chat */}
                    <Col md={6} >
                        <Accordion className='transparent' defaultActiveKey="0" flush>
                            <Accordion.Item className='transparent' eventKey="0">
                                <Accordion.Header>Our Commitment to Data Privacy</Accordion.Header>
                                <Accordion.Body>At Ashera, we prioritize the privacy and security of your data. Our comprehensive privacy policy is designed to protect your information and provide you with peace of mind, knowing that your data is handled with the utmost care and confidentiality.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="1">
                                <Accordion.Header>Data Collection and Use</Accordion.Header>
                                <Accordion.Body>We collect only the data necessary to deliver our services effectively. This includes information you provide directly, as well as data generated through the use of our services. All data collection processes are transparent, and we ensure you are informed about what data is being collected and how it will be used.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="2">
                                <Accordion.Header>Data Security</Accordion.Header>
                                <Accordion.Body>We implement robust security measures to safeguard your data against unauthorized access, alteration, or disclosure. Our security protocols include encryption, secure data storage, and access controls. Regular security audits and updates are conducted to ensure our systems remain resilient against emerging threats.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="3">
                                <Accordion.Header>Data Anonymization</Accordion.Header>
                                <Accordion.Body>Wherever possible, we anonymize your data to protect your identity. Anonymization techniques are employed to strip personal identifiers from data sets, ensuring that your information cannot be traced back to you.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="4">
                                <Accordion.Header>Limited Data Sharing</Accordion.Header>
                                <Accordion.Body>We do not share your data with third parties without your explicit consent. In cases where data sharing is necessary for service provision or regulatory compliance, we ensure that third parties adhere to strict confidentiality and data protection standards.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="5">
                                <Accordion.Header>User Control</Accordion.Header>
                                <Accordion.Body>We believe in empowering you with control over your data. Our privacy policy provides clear guidelines on how you can access, update, or delete your information. You can also manage your privacy preferences and opt-out of certain data collection practices.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="6">
                                <Accordion.Header>Compliance with Regulations</Accordion.Header>
                                <Accordion.Body>Our privacy practices comply with all relevant data protection regulations, including GDPR, CCPA, and other applicable laws. We are committed to maintaining compliance and regularly review our policies to align with legal requirements.</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="7">
                                <Accordion.Header>Transparency and Accountability</Accordion.Header>
                                <Accordion.Body>We are transparent about our data handling practices and provide clear communication regarding any changes to our privacy policy. Our team is always available to address any questions or concerns you may have about how your data is managed</Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className='transparent' eventKey="8">
                                <Accordion.Header>Conclusion</Accordion.Header>
                                <Accordion.Body>At Ashera, your privacy is our priority. Our stringent privacy policy ensures that your data is protected at all times, allowing you to use our services with confidence. We are dedicated to upholding the highest standards of data privacy and security, safeguarding your information while delivering exceptional service.</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Privacy;
