import React from 'react';

const TypingDots: React.FC = () => {
    return (
        <div style={{ display: 'flex', gap: '4px' }}>
            <div style={dotStyle} />
            <div style={{ ...dotStyle, animationDelay: '0.2s' }} />
            <div style={{ ...dotStyle, animationDelay: '0.4s' }} />
        </div>
    );
};

const dotStyle: React.CSSProperties = {
    width: '8px',
    height: '8px',
    backgroundColor: '#333',
    borderRadius: '50%',
    animation: 'blink 1.4s infinite both'
};

// Adding the keyframes animation as a global style
const styles = `
@keyframes blink {
    0%, 80%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}
`;

// Inject the global styles into the document head
if (typeof document !== 'undefined') {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
}

export default TypingDots;
